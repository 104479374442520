.login-form {
  margin: 30px auto;
  max-width: min(100%, 400px);

  button {
    max-width: 400px;
  }
}

.required::after {
  content: "*";
  color: #f44336;
  font-size: 1.125rem;
  position: absolute;
  top: 0;
  left: auto;
  margin-left: 2px;
}

.required::before {
  content: none;
}
