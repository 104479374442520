.blaze-user-form,
.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .form-field {
    &--checkbox {
      align-items: start;
    }
  }

  .form-checkbox {
    background-color: white;
    border: 1px solid #C5C1C1 !important;
  }

  &__input-group select {
    border-radius: 5px;
    border: 1px solid #C5C1C1;
    padding: 10px;
  }

  input:not([type="radio"]) {
    padding: 10px;
    font-size: 0.8rem;
    border-radius: 5px;
  }

  button {
    background-color: $primary-button-color;
    cursor: pointer;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 1rem;
    color: $white;

    .blaze-loading__spinner {
      width: 24px;
      height: 24px;
    }
  }

  .error_message_wrapper {
    display: flex;
    align-items: center;
    margin: 20px 0;
    flex-direction: row;

    .error_message_icon {
      border: 2px solid $warning;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 50%;
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }

    .error_message {
      width: 100%;
      color: $warning;
      font-size: 16px;
      font-weight: 300;
      text-align: left;
    }
  }

  &__checkbox_input {
    &__input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .checkbox-wrapper {
        width: 100%;
        display: flex;
        margin-top: 20px;
        margin-bottom: 5px;

        .form-field {
          margin: 0;
          width: 100%;
          width: 35px;
        }

        label {
          font-size: 0.9rem;
          text-align: left;

          a {
            color: $link-color;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .validation {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.7rem;
        gap: 0.5rem;
        text-align: left;
        margin-top: 5px;

        i {
          font-size: 1.5rem
        }
      }
    }
  }

  &__input-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 0.5rem;
    gap: 1rem;


    &__input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      &.county {
        .form-field--select {
          .required {
            margin-left: 0px;

            &:before {
              content: "";
              font-size: 1.125rem;
              position: absolute;
              left: -12px;
              top: -2px;
            }
          }
        }
      }

      .form-field {
        width: 100%;
        max-width: 100%;
        margin: 0px;

        .validation {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.7rem;
          gap: 0.5rem;
          text-align: left;
          margin-top: 5px;

          i {
            font-size: 1.5rem
          }
        }
      }

      &.error {
        input {
          border: 1px solid red;
        }

        select {
          border-bottom: 1px solid red;
        }

        .show-hide-password {
          bottom: 40px;
        }
      }

      input {
        font-size: 0.8rem;
        border-radius: 5px;
      }

      label {
        font-size: 0.8rem;

        a {
          color: $link-color;
        }

        &.error {
          color: red;
        }
      }
    }

    select {
      height: 40px;
    }
  }

  &__submit-button {
    margin-top: 20px;
  }
}
