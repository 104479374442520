.page-content {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: $font-semi-bold;
    font-family: $primary-font;
  }

  h1 {
    font-size: $font-med;
    font-weight: $font-bold;

    @include mq($from: tablet) {
      font-size: $font-med;
      font-weight: $font-regular;
    }

    @include mq($from: desktop) {
      font-size: $font-med;
      font-weight: $font-regular;
    }
  }

  h2 {
    font-size: $font-body-med;
    margin: 0 0 30px;

    @include mq($from: tablet) {
      font-size: $font-body-med;
    }

    @include mq($from: desktop) {
      font-size: $font-body-med;
    }
  }

  h3 {
    font-size: $font-body-med;
    margin: 0 0 20px;

    @include mq($from: tablet) {
      font-size: $font-body-med;
    }

    @include mq($from: desktop) {
      font-size: $font-body-med;
    }
  }

  h4 {
    font-size: $font-body;
    margin: 0 0 20px;

    @include mq($from: tablet) {
      font-size: $font-body;
    }

    @include mq($from: desktop) {
      font-size: $font-body;
    }
  }

  h5 {
    font-size: $font-small;
    margin: 0 0 20px;

    @include mq($from: tablet) {
      font-size: $font-small;
    }

    @include mq($from: desktop) {
      font-size: $font-small;
    }
  }

  h6 {
    font-size: $font-x-small;
    margin: 0 0 20px;

    @include mq($from: tablet) {
      font-size: $font-x-small;
    }

    @include mq($from: desktop) {
      font-size: $font-x-small;
    }
  }

  p {
    font-family: $primary-font;
    font-size: $font-body;
    margin: 0 0 20px;

    @include mq($from: tablet) {
      font-size: $font-body;
    }

    & a {
      @include link-basic;
      color: $link-color;
      padding-bottom: 0;
      border-color: transparent;

      &:hover {
        border-color: $link-color;
      }
    }
  }

  ol,
  ul {
    font-family: $primary-font;
    font-size: $font-body;
    margin: 0 0 20px 20px;

    @include mq($from: tablet) {
      font-size: $font-body;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  li {
    margin-bottom: 1rem;
  }

  blockquote {
    font-family: $primary-font;
    text-align: left;
    font-style: italic;
    font-size: $font-body-large;
    line-height: 1.5em;
    padding: 0;
    margin: 0 0 20px;
    quotes: none;

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }

    & strong {
      display: inline-block;
      width: 100%;
      font-size: 1rem;
      letter-spacing: $small-space;
    }
  }

  img {
    margin: 0;
    width: auto;

    @include mq($from: tablet) {
      margin: 0;
    }
  }

  code {
    padding: 5px;
    background-color: $grey-lightest;
    border-radius: 5px;
    color: $red;
    font-size: 1rem;
  }

  & .heading--large {
    margin-bottom: 35px;
  }

}

.tick-bullets {
  ul {
    list-style-image: url("/static/img/tick-green.png");
  }
}

.lightest-grey-bg:has(.tick-bullets) {
  padding: 10px 35px;
}

h2.heading--large {
  font-size: $font-small;
  color: $grey-medium;
}

b,
strong {
  font-weight: 600 !important;
}

.column--sidebar .card__details span:last-of-type {
  font-weight: 600;
}

.white-text {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  blockquote,
  .menu--desktop-wrapper li a {
    color: $white !important;
  }
}


.align {
  &--center {

    h1,
    .heading,
    h2,
    h3,
    h4,
    p,
    .intro,
    li,
    blockquote,
    .heading--large,
    .image {
      text-align: center;
      justify-content: center;
    }

    width: fit-content;
    margin: 0 auto !important;
  }


}

.line-clamp {
  &--10 {
    .card__details {
      span {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        line-clamp: 10;
        -webkit-box-orient: vertical;
      }
    }
  }
}
